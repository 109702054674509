import React from 'react';
import PropTypes from 'prop-types';
import {HomeStyle} from './home.style'
import {withStyles} from "@material-ui/core/styles/index";
import LogoImg from './../../assets/images/copre-fashion-sm.png';

const HomeComponent = (props) => {

    const classes = props.classes;

    return (
        <div className={classes.root}>
            <img src={LogoImg} width={340}alt={'Copre logo'} className={props.classes.img}/>
        </div>
    );
};

HomeComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(HomeStyle)(HomeComponent);