import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import HomeComponent from "./components/home/home.component";
import AboutComponent from './components/about/about.component';
import ContactComponent from './components/contact/contact.component';
import {theme} from './App.style'
import { MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';


const App = (props) => {

    return (
        <MuiThemeProvider theme={theme}>
            <React.Fragment>
                <CssBaseline/>
                <Container>
                    <BrowserRouter>
                        <HeaderComponent/>

                        <Switch>
                            <Route path="/home" exact
                                   render={(props) => <HomeComponent  {...props}/>}
                            />
                            <Route path="/about" exact
                                   render={(props) => <AboutComponent  {...props}/>}
                            />
                            <Route path="/contacts" exact
                                   render={(props) => <ContactComponent  {...props}/>}
                            />
                            <Redirect to="/home" />
                        </Switch>

                    </BrowserRouter>
                </Container>
            </React.Fragment>
        </MuiThemeProvider>
    );
};

export default App;
