import React from 'react';
import PropTypes from 'prop-types';
import {ContactStyle} from './contact.style'
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import contactImg from './../../assets/images/contacts-xs.png';

const ContactComponent = (props) => {

    const classes = props.classes;

    return (
        <Container maxWidth="md">
            <Box textAlign="center" mt={25}>
            <img src={contactImg} className={classes.contact} width={100} alt={'ContactImg'}/>
                <Typography variant="subtitle2" className={classes.text}>
                    <a href="mailto:info@copreconsulting.com?subject=INFO" className={classes.text}>info@copreconsulting.com</a>

                </Typography>
                <Typography variant="subtitle2" className={classes.text}>62 Hewison St</Typography>
                <Typography variant="subtitle2" className={classes.text}>London</Typography>
                <Typography variant="subtitle2" className={classes.text}>E3 2HY</Typography>

                <Box textAlign="center" className={classes.regBox}>
                    <Typography variant="subtitle2" className={classes.regText}>REGISTERED COMPANY NUMBER</Typography>
                    <Typography variant="subtitle2" className={classes.regText}>09027126</Typography>
                </Box>

            </Box>
        </Container>
    );
};

ContactComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(ContactStyle)(ContactComponent);