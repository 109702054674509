import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router-dom';
import copreImg from './../../assets/images/copre-xs.png';

const styles = {
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: 16,
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    copre: {
        marginTop: 10
    }
};

class HeaderComponent extends Component {

    state = {
        showLogo: true
    };


    toggleLogo = (value) => {
        this.setState({ showLogo: value });
    };

    goTo = (path) => {

        if (path === '/home') {
            this.toggleLogo(false)
        }

        else {
            this.toggleLogo(true)
        }
        this.props.history.push(path);
    };

    componentDidMount = () => {

        const pathname = this.props.location.pathname;

        if (pathname === '/contacts' || pathname === '/about') {
            this.toggleLogo(true);
        }

        else {
            this.toggleLogo(false);
        }
    };


    render() {

        const { classes } = this.props;

        const { showLogo } = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" display="inline" className={classes.title}>

                            {
                                showLogo ?

                                <img src={copreImg} className={classes.copre} width={70} alt={'CopreImg'} onClick={() => this.goTo('/home')}/>

                                    :

                                    null
                            }
                        </Typography>
                        <Button color="inherit" onClick={() => this.goTo('/about')}>About</Button>
                        <Button color="inherit" onClick={() => this.goTo('/contacts')}>Contacts</Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

HeaderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(props => <HeaderComponent {...props}/>));
