import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const SPACING = 8;

const spacing = (value) => value * SPACING;


let muiTheme = createMuiTheme({
    palette: {
        primary: grey
    },
    typography: {
        fontFamily: ['VT323', 'monospace'].join(','),
        htmlFontSize: 10,

    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiInput: {
            inputProps: {
                maxLength: 100
            }
        }
    },
    overrides: {
        MuiFormControl: {
            root: {
                marginBottom: spacing(3)
            }
        }
    }
});

export const theme = responsiveFontSizes(muiTheme);