import React from 'react';
import PropTypes from 'prop-types';
import {AboutStyle} from './about.style'
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import copreImg from './../../assets/images/copre-sm.png';

const AboutComponent = (props) => {

    const classes = props.classes;

    return (
        <Container maxWidth="md">
            <Box textAlign="center" mt={20}>
            <img src={copreImg} className={classes.copre} width={240} alt={'CopreImg'}/>
                <Typography variant="subtitle2" className={classes.text}>is a Fashion Service Agency based in London since 2015, a team of forward thinking and technology minds with a 360° approach to its costumers.</Typography>
                <Typography variant="subtitle2" className={classes.text}>
                    With a focus on fashion, Copre develops customized strategies for companies, taking care of each aspect of their brand: from the art direction to the product development and supply chain; from the social media management to public relations and web strategies, assisting them to empower their brand identity through rebranding and repositioning.
                </Typography>
                <Typography variant="subtitle2" className={classes.text}>
                    Copre is specialized in luxury market and Italian manufacturing, we create the right connection with the best players.
                </Typography>
                <Typography variant="subtitle2" className={classes.text}>
                    Clients see results with a measurable impact.
                </Typography>
            </Box>

        </Container>
    );
};

AboutComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(AboutStyle)(AboutComponent);