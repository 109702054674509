export const HomeStyle = {
    root: {
        textAlign: 'center',
        position: 'relative',
        minHeight: '100%'
    },
    typography: {
        color: 'white',
    },
    img: {
        marginTop: '260px'
    },
    fashionConsText: {
        fontFamily: 'Helvetica',
        fontWeight: 600,
        marginTop: '-15px',
        fontSize: '1.2rem'
    }
};