export const ContactStyle = {

    root: {
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        paddingTop: '50px',
        textAlign: 'center'
    },
    head: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: 26,
        fontWeight: 600
    },
    regBox: {
        position: 'fixed',
        width: '100%',
        left: 0,
        bottom: 40,
        right: 0,

    },

    regText: {
        fontSize: 16
    },

    text: {
        color: '#B6B8B5',
        fontSize: 17
    },
    contact: {

    }
};